<template>
  <div>可视化场景组装</div>
</template>
<script>
export default {
  setup() {
    return {
      //
    };
  },
};
</script>
